<template>
  <div>
    <b-modal
      id="processing-users"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('Employees')"
      hide-footer
      @close="onCancel"
    >
      <b-form-group>
        <label style="font-weight: bold;">{{ $t('CurrentEmployees') }}</label>
        <div
          v-for="item in getProcessingEmployees.assingedUsesStateResponseDto"
          :key="item"
          class="modalof"
        >
          {{ item.processingUserName }}
          <span>
            <b-icon-x-circle
              :key="item.processingEventId"
              v-b-tooltip.focus
              :title="`Are u sure u want to delete ` + item.processingUserName"
              style="margin-left: 25px; margin-right: 5px;cursor: pointer;"
              @click="handleDeleteClick(item)"
            />
          </span>
        </div>
      </b-form-group>
      <div
        v-if="currentStateOfArticle > 2 && currentStateOfArticle < 14"
      >
        <b-form-group v-if="buttonSubmit">
          <b-button
            class="buttonSubmit"
            style="width: 50%;margin-top: 10px;"
            @click="buttonBehaviour"
          >
            {{ $t("AddEmployee") }}
          </b-button>
        </b-form-group>
        <b-form-group
          v-if="!buttonSubmit"
        >
          <label
            style="font-weight: bold;margin-top: 10px;cursor: pointer;"
            @click="buttonUnclick"
          >{{ $t("AddEmployee") }}<b-icon-x-circle style="margin-left: 5px;" />
          </label>

          <vue-select
            v-model="userIds"
            :options="returnEmployees"
            label="name"
            :reduce="(e) => e.userId"
            :multiple="true"
            :placeholder="$t('SelectEmployee')"
            style="width: 100%;margin-top: 10px;"
          />
          <div class="buttonsEverywhere">
            <b-button
              type="button"
              variant="none"
              class="buttonSubmit"
              style="background: #FF274F !important;"

              @click="onSubmit"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="danger"
              class="buttonCancel"
              style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form-group>
      </div>
    </b-modal></div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['stateEmployee', 'orderItemIdEmployee', 'currentStateOfArticle'],
  data() {
    return {
      notes: '',
      buttonSubmit: true,
      userIds: null,
    }
  },
  computed: {
    ...mapGetters([
      'getProcessingEmployees',
      'getTeamOverview',
    ]),
    returnEmployees() {
      const processingUsers = this.getProcessingEmployees.assingedUsesStateResponseDto;
      const filteredUsers = this.getTeamOverview.users.filter((user) => {
        return !processingUsers.some((processingUser) => { return processingUser.processingUserName === user.name });
      });
      return filteredUsers;
    },
    startTrackAndTraceId() {
      const processingEmployees = this.getProcessingEmployees;
      if (processingEmployees && processingEmployees.startTrackAndTraceId) {
        return processingEmployees.startTrackAndTraceId;
      }
      return null;
    },
  },
  methods: {
    ...mapActions(['removeEmployee', 'trackProcessingEmployees']),
    buttonBehaviour() {
      this.buttonSubmit = false
    },
    buttonUnclick() {
      this.buttonSubmit = true
      // this.userIds = null
    },
    handleDeleteClick(item) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this user?', {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        cancelVariant: 'none',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          if (value) {
            console.log('item', item);
            this.deleteUser(item.processingEventId);
          }
        })
        .catch((err) => {
          console.log('Error in msgBoxConfirm:', err);
        });
    },

    async deleteUser(processingEventId) {
      console.log('delete', processingEventId);
      const objekti = {
        processingEventId,
        notes: null,
      };

      await this.removeEmployee({
        object: objekti,
        successCallback: () => {
          this.trackProcessingEmployees({
            orderState: this.stateEmployee,
            windowItemId: this.orderItemIdEmployee,
          })
        },
      });
    },
    onSubmit() {
      const objekti = {
        startTrackAndTraceId: this.startTrackAndTraceId,
        userIds: this.userIds,
      }
      this.$emit('addedEmployees', objekti)
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.onCancel()
    },
    onCancel() {
      this.buttonSubmit = true
      this.userIds = null
    },
  },
}
</script>

<style scoped>
.modalof{
  border:1px solid lightgray;
  padding: 5px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-control {
  padding: 10px;
  background: white;
}
</style>
